export default class SettingsService {
  constructor() {
    this.settings = null;
  }

  getSettings = async () => {
    let environment = process.env.NODE_ENV
    let settingsFileAddress = ""
    if (environment === "development") {
      settingsFileAddress = "/settings/settings.local.json"
    } else {
      settingsFileAddress = "/settings/settings.json"
    }

    const settingsResponse = await fetch(settingsFileAddress)
    const settingsObject = await settingsResponse.json()

    this.settings = settingsObject
    return settingsObject
  }

}