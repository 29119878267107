<template>

  <div>

    <template default="props">
      <div class="modal-card access-breakdown-modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Subscription access breakdown</p>
          <button type="button" class="delete" @click="$emit('close')"/>
        </header>
        <section class="access-breakdown-modal-body modal-card-body">
          <div class="content">
            <div v-if="breakdownForRole.length > 0">
              <p>
                <span class="has-text-weight-bold">{{ principalName }}</span> has <span
                  class="has-text-weight-bold">{{ roleName | capitalise }}</span> access on
                <span class="has-text-weight-bold">{{ subscriptionEntitlements.subscription }}</span> as follows
              </p>

              <b-table :data="breakdownForRole" :narrowed="true" class="modal-table-light" :detailed="true"
                       :has-detailed-visible="row => showDetailsForGroupsOnly(row)">
                <b-table-column field="reader" label="Name">
                  <template v-slot="props">{{ formattedPrincipalName(props.row) }}</template>
                </b-table-column>

                <b-table-column field="reader" label="Type">
                  <template v-slot="props">{{ props.row.principalType | capitalise }}</template>
                </b-table-column>

                <b-table-column field="reader" label="Assignment type">
                  <template v-slot="props">{{ props.row.assignmentState | assignmentCaption }}</template>
                </b-table-column>

                <b-table-column field="reader" label="Membership type">
                  <template v-slot="props">{{ props.row.memberType | capitalise }}</template>
                </b-table-column>

                <template slot="detail" slot-scope="props" class="modal-table-light-details">
                  <HierarchyTreeForModalTableLight  :api-response="apiResponse" :child-type="apiResponse.principalType" :row="props.row">
                  </HierarchyTreeForModalTableLight>
                </template>
              </b-table>
            </div>

            <div v-else>
              <p>
                <span class="has-text-weight-bold">{{ principalName }}</span> has no <span class="has-text-weight-bold">
                {{ roleName | capitalise }}</span> access on <span class="has-text-weight-bold">
                {{ subscriptionEntitlements.subscription }}</span>
              </p>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot has-text-right">
          <p class="is-italic is-size-7 has-text-right">Data delayed by upto five minutes</p>
        </footer>
      </div>
    </template>

  </div>

</template>

<script>
import HierarchyTreeForModalTableLight from "@/components/Home/HierarchyTrees/HT-ModalTableLight";

export default {
  name: "AccessBreakdownModal",

  props: {
    principalName: String,
    roleName: String,
    subscriptionEntitlements: Object,
    apiResponse: Object,
  },

  components: {
    HierarchyTreeForModalTableLight: HierarchyTreeForModalTableLight
  },

  async mounted() {
    let initialBreakdownRows = this.breakdownForRole.filter(row => row.principalType === 'group')
    await this.preEmptiveInheritanceCacheForBreakdownRows(initialBreakdownRows)
  },

  methods: {
    preEmptiveInheritanceCacheForBreakdownRows: async function(rowsOnPage) {
      // Get the distinct capabilities
      let uniqueAdGroups = rowsOnPage.filter((row, index, self) =>
          index === self.findIndex((t) => (
              t.principalObjectId === row.principalObjectId &&
              t.assignmentState === row.assignmentState &&
              t.memberType === row.memberType &&
              t.status === row.status
          ))
      )

      // Set the isCached property to false by default
      uniqueAdGroups.forEach((row, index, array) => {
        row["isCached"] = false
      })

      // Mark the rows which don't need to be cached
      let childType = this.$props.apiResponse.principalType
      let childUpnOrChildGroupId = this.getChildUpnOrChildGroupId(childType)
      uniqueAdGroups.forEach((row, index, array) => {
        let isCached = this.$cacheService.getItemFromInheritanceCache(
            row.principalName,
            row.principalObjectId,
            childUpnOrChildGroupId
        )
        if (isCached !== false) {
          row["isCached"] = true
        }
      })

      let toBeCached = uniqueAdGroups.filter(row => row.isCached === false)
      let tobeCachedUrls = []
      let cachedResults = []

      // Populate an array of URLs that we will later need to call to populate the cache
      toBeCached.forEach((row, index, array) => {

        let url = this.$settings.apiBaseUri
        if (childType === 'user') {
          url += `EnumerateGroupInheritance?childType=user`
          url += `&parentGroupName=${row.principalName}`
          url += `&parentGroupId=${row.principalObjectId}`
          url += `&upn=${childUpnOrChildGroupId}`
        } else {
          url += `EnumerateGroupInheritance?childType=group`
          url += `&parentGroupName=${row.principalName}`
          url += `&parentGroupId=${row.principalObjectId}`
          url += `&childGroupId=${childUpnOrChildGroupId}`
        }
        tobeCachedUrls.push(url)
      })

      // Call each of the URLs in parallel
      await Promise.all(tobeCachedUrls.map(url => this.$http.get(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${(this.$authService.rbacAccessToken)}`
        }
      }))).then((function (result) {
        cachedResults.push([result])
      }))

      // Append hierarchies to caching service
      cachedResults[0][0].forEach((result, index, array) => {
        let queryStrings = new URLSearchParams(result.config.url)
        this.$cacheService.addItemToInheritanceCache(
            queryStrings.get("parentGroupName"),
            queryStrings.get("parentGroupId"),
            childUpnOrChildGroupId,
            result.data
        )
      })
    },

    getChildUpnOrChildGroupId: function (childType) {
      if (childType === "user") {
        return this.$props.apiResponse.principalName.toString().toLowerCase()
      } else {
        return this.$props.apiResponse.principalId.toString().toLowerCase()
      }
    },

    formattedPrincipalName: function (row) {
      if (row.principalType.toLowerCase() === 'group') {
        return row.principalName
      } else {
        return row.principalName.toLowerCase()
      }
    },

    showDetailsForGroupsOnly: function (row) {
      if (row.principalType.toLowerCase() === 'user') {
        return false
      }
      return true;
    },
  },

  computed: {
    breakdownForRole: function () {
      if (this.roleName.toLowerCase() === "reader") {
        return this.subscriptionEntitlements.access.readerBreakdown
      } else if (this.roleName.toLowerCase() === "contributor") {
        return this.subscriptionEntitlements.access.contributorBreakdown
      } else {
        return this.subscriptionEntitlements.access.ownerBreakdown
      }
    },
  },

  filters: {
    capitalise: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },

    assignmentCaption: function (value) {
      if (value.toLowerCase() == 'active') {
        return 'Direct (no PIM)'
      } else {
        return 'Eligible (PIM)'
      }
    },

    principalIcon: function (principalType) {
      if (principalType === "user") {
        return "account"
      } else {
        return "account-group"
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>