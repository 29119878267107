<template>
  <div id="searchResults">
    <h1 class="pt-3 pb-2 is-size-4">
      Resource groups in {{ apiResponse.resourceType | prettifiedResourceType }}
      <strong class="has-text-gold">{{ apiResponse.resourceName }}</strong>
      <b-tooltip position="is-right" animated multilined type="is-white">
        <template v-slot:content><span class="is-size-7">If you cannot find a resource group/subscription you were expecting to see, it may not have been processed by the RBAC automation system. Speak to the Enterprise Cloud Engineering team for any assistance.</span>
        </template>
        <span class="icon"><i class="mdi mdi-18px mdi-information-outline has-text-info"></i></span>
      </b-tooltip>
    </h1>
    <template v-if="apiResponse.azureResources.length > 0">

      <section>
        <b-table :data="this.apiResponse.azureResources" :paginated="true" :per-page="this.tablePageCount"
                 :narrowed="true" :hoverable="true" :debounce-search="250" aria-next-label="Next page"
                 aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
                 class="core-table" ref="subTbl">

          <p class="has-text-right table-pagination-dynamic">
            Showing {{ offsetStart }} to {{ offsetEnd }} of {{ totalRecords }} records
          </p>

          <b-table-column field="subscription" label="Subscription" sortable searchable>
            <template slot="searchable" slot-scope="props">
              <b-input v-model="props.filters[props.column.field]" placeholder="Search..." icon="magnify"
                       size="is-small"/>
            </template>
            <template v-slot="props">
              {{ props.row.subscription }}
            </template>
          </b-table-column>

          <b-table-column field="resourceGroup" label="Resource group" sortable searchable>
            <template slot="searchable" slot-scope="props">
              <b-input v-model="props.filters[props.column.field]" placeholder="Search..." icon="magnify"
                       size="is-small"/>
            </template>
            <template v-slot="props">
              {{ props.row.resourceGroup }}
            </template>
          </b-table-column>

          <b-table-column field="capabilityTeam" label="Capability team" sortable searchable>
            <template slot="searchable" slot-scope="props">
              <b-input v-model="props.filters[props.column.field]" placeholder="Search..." icon="magnify"
                       size="is-small"/>
            </template>
            <template v-slot="props">
              {{ props.row.capabilityTeam }}
            </template>
          </b-table-column>

          <template slot="empty"><p class="has-text-centered">No results found matching criteria</p></template>

          <template slot="bottom-left">
            <b-button @click="exportToCsv('azureResources')">
              <span class="icon pr-1"><i class="mdi mdi-18px mdi-file-download-outline"></i></span>
              Export to CSV
            </b-button>
          </template>
        </b-table>
      </section>
    </template>
    <template v-else>
      <p>This subscription has not been onboarded to the RBAC automation system.</p>
    </template>

  </div>
</template>

<script>
import FileExportService from "@/services/FileExportService";

export default {
  name: 'ResponseTemplate_ResourceApi',

  data: function () {
    return {
      tablePageCount: 15,
      offsetStart: 1,
      offsetEnd: Math.min(this.$props.apiResponse.azureResources.length, 15),
      totalRecords: this.$props.apiResponse.azureResources.length
    }
  },

  props: {
    apiResponse: Object
  },

  mounted() {
    // Watchers to monitor table changes for dynamic pagination
    this.$watch("$refs.subTbl.newDataTotal", (new_value, old_value) => {
      if (new_value === 0) {
        this.offsetStart = 0
        this.offsetEnd = 0
      } else {
        this.offsetStart = (this.$refs.subTbl.newCurrentPage - 1) * this.tablePageCount + 1
        this.offsetEnd = Math.min(this.tablePageCount, new_value)
      }
      this.totalRecords = new_value
    });

    this.$watch("$refs.subTbl.newCurrentPage", (new_value, old_value) => {
      this.offsetStart = (new_value - 1) * this.tablePageCount + 1
    });

    this.$watch("$data.offsetStart", (new_value, old_value) => {
      let offsetEnd = new_value + (this.tablePageCount - 1)
      this.offsetEnd = Math.min(offsetEnd, this.$refs.subTbl.newDataTotal)
    });
  },

  methods: {
    exportToCsv: async function (entitlementType) {
      let fileExportService = new FileExportService()
      let data = this.$props.apiResponse;
      let env = this.$settings.environment
      await fileExportService.generateCsv(entitlementType, data, env)
    }
  },

  filters: {
    prettifiedResourceType: function (value) {
      if (!value) {
        return ''
      } else if (value.toString() === "subscription") {
        return "subscription"
      } else if (value.toString() === "resourceGroup") {
        return "resource group"
      }
    }
  }
}
</script>