<template>
  <div class="content">

    <div v-show="isLoading" class="loadingSpinner columns is-centered" style="margin: 0 auto;">
      <b-notification :closable="false" class="column is-1" style="height: 110px;">
        <b-loading style="border-radius: 10px; " v-model="isLoading" :is-full-page="false"
                   :closable="false"></b-loading>
      </b-notification>
    </div>

    <div v-show="!isLoading" class="columns">
      <div class="column">
        <h1 class="is-size-5">Capability team membership hierarchy</h1>
        <b-steps vertical :has-navigation="false" size="is-small" :clickable="false" :destroy-on-hide="true">
          <b-step-item v-for="(hierarchyLevel, index) in this.hierarchy" :key="index"
                       :label="hierarchyLevel.principalName" headerClass="is-active" icon-pack="mdi"
                       :icon="hierarchyLevel.principalType | principalIcon">
          </b-step-item>
        </b-steps>
      </div>
      <div class="column">
        <h1 class="is-size-5">Access mechanisms</h1>
        <p v-if="environment === 'rnd' || environment === 'nonprd'">Operator and Writer rights on this
          resource group are direct (no PIM required).</p>
        <p v-if="environment === 'prd'">Operator rights on this resource group are direct (no PIM required).
          Writer rights on this resource group are eligible (PIM required).</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "HierarchyTreeForCoreTable",

  data: function () {
    return {
      isLoading: true,
      environment: this.$settings.environment,
      hierarchy: null
    }
  },

  props: {
    apiResponse: Object,
    childType: String,
    row: Object
  },

  async mounted() {

    let url = this.$settings.apiBaseUri
    let childUpnOrChildGroupId = null

    if (this.$props.childType === "user") {
      url += `EnumerateGroupInheritance?childType=user`
      url += `&parentGroupName=${this.$props.row.capabilityTeam}`
      url += `&parentGroupId=${this.$props.row.capabilityTeamObjectId}`
      url += `&upn=${this.$props.apiResponse.principalName}`

      childUpnOrChildGroupId = this.$props.apiResponse.principalName.toString().toLowerCase()
    } else if (this.$props.childType === "group") {
      url += `EnumerateGroupInheritance?childType=group`
      url += `&parentGroupName=${this.$props.row.capabilityTeam}`
      url += `&parentGroupId=${this.$props.row.capabilityTeamObjectId}`
      url += `&childGroupId=${this.$props.apiResponse.principalId}`

      childUpnOrChildGroupId = this.$props.apiResponse.principalId.toString().toLowerCase()
    }

    // Check if the inheritance for the provided key exists in cache. If not, get the inheritance details from the API
    const isExist = this.$cacheService.getItemFromInheritanceCache(
        this.$props.row.capabilityTeam,
        this.$props.row.capabilityTeamObjectId,
        childUpnOrChildGroupId
    )

    if (isExist === false) {
      this.$http.get(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${(this.$authService.rbacAccessToken)}`
        }
      }).then(response => {
        if (response.status === 200) {
          this.hierarchy = response.data
          this.$cacheService.addItemToInheritanceCache(
              this.$props.row.capabilityTeam,
              this.$props.row.capabilityTeamObjectId,
              childUpnOrChildGroupId,
              this.hierarchy
          )

          this.isLoading = false
        }
      })
    } else {

      let item = this.$cacheService.getItemFromInheritanceCache(
          this.$props.row.capabilityTeam,
          this.$props.row.capabilityTeamObjectId,
          childUpnOrChildGroupId
      )
      this.hierarchy = item

      // setTimeout(() => this.isLoading = false, 450)  <-- need to conduct some UX testing for this IMO
      this.isLoading = false
    }
  },

  filters: {
    principalIcon: function (principalType) {
      if (principalType === "user") {
        return "account"
      } else {
        return "account-group"
      }
    }
  }
}
</script>

<style scoped>

</style>