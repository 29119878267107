import Vue from 'vue'
import App from './App.vue'

import router from './router'
import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

import Buefy from 'buefy'
import "./assets/custom.scss";

import SettingsService from './services/SettingsService'
import AuthService from './services/AuthService'
import CacheService from './services/CacheService'
import VueAppInsights from 'vue-application-insights'

Vue.use(Buefy)

Vue.config.productionTip = false

const main = async () => {
  Vue.prototype.$settings = await (new SettingsService()).getSettings()

  Vue.prototype.$cacheService = await (new CacheService())

  Vue.prototype.$http = axios.create({})

  // Vue.observable gives us 'reactivity' over external objects such as updates to the AuthService
  Vue.prototype.$authService = Vue.observable(await (new AuthService(Vue.prototype.$settings)))
  await Vue.prototype.$authService.init()

  // Get all subscriptions in management group
  let url = Vue.prototype.$settings.apiBaseUri +  `EnumerateSubscriptions`
  let response = await Vue.prototype.$http.get(url, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${(Vue.prototype.$authService.rbacAccessToken)}`
    },
  })
  Vue.prototype.$allSubscriptionsInManagementGroup = response.data

  // Global interceptor for dealing with token expiry
  const refreshTokenInterceptor = failedRequest => Vue.prototype.$authService.requestNewToken().then(() => {
    if (failedRequest.config.url.includes("graph.microsoft.com")) {
      failedRequest.config.headers['Authorization'] = `Bearer ${(Vue.prototype.$authService.graphAccessToken)}`
    } else if (failedRequest.config.url.includes(Vue.prototype.$settings.apiBaseUri)) {
      failedRequest.config.headers['Authorization'] = `Bearer ${(Vue.prototype.$authService.rbacAccessToken)}`
    }
    return Promise.resolve();
  });
  createAuthRefreshInterceptor(Vue.prototype.$http, refreshTokenInterceptor, {
    statusCodes: [401, 403]
  })

  Vue.use(VueAppInsights, {
    id: Vue.prototype.$settings.appInsightsInstrumentationKey,
    baseName: `CARMA-${Vue.prototype.$settings.environment}`,
    router,
  })
}

main().then(() => {
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')
});

