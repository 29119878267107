import * as Msal from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId:    null,                // client ID of the CARMA service principal
    authority:   null,                // tenant ID
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
};

export default class AuthenticationService {

  isLoggedIn = false
  tokenResponse = null
  graphAccessToken = null
  rbacAccessToken = null
  roles = []


  constructor(settings) {
    msalConfig.auth = {
      clientId: `${settings.servicePrincipalApplicationId}`,
      authority: `https://login.microsoftonline.com/${settings.tenantId}/`,
      redirectUri: `${settings.msalRedirectUri}`
    }

    this.app = new Msal.PublicClientApplication(msalConfig)
  }

  init = async () => {

    try {
      let tokenResponse = await this.app.handleRedirectPromise()
      let accountObj

      if (tokenResponse) {
        accountObj = tokenResponse.account;
      } else {
        accountObj = this.app.getAllAccounts()[0];
      }

      if (accountObj && tokenResponse) {
        this.setStateToLoggedIn(tokenResponse)
      } else if (accountObj) {
        try {
          tokenResponse = await this.app.acquireTokenSilent({
            account: this.app.getAllAccounts()[0],
            scopes: ["user.read", "directory.read.all"]
          })
          this.setStateToLoggedIn(tokenResponse)
        } catch(err) {
          await this.app.acquireTokenRedirect({scopes: ["user.read", "directory.read.all"]});
        }
      } else {
        await this.app.loginRedirect({scopes: ["user.read", "directory.read.all"]})
      }
    } catch (error) {
      console.error("[AuthService.init] Failed to handleRedirectPromise()", error)
      window.location.reload()
    }

  }

  setStateToLoggedIn (tokenResponse) {
    this.isLoggedIn = true
    this.tokenResponse = tokenResponse
    this.graphAccessToken = tokenResponse.accessToken
    this.rbacAccessToken = tokenResponse.idToken
    this.roles = tokenResponse.idTokenClaims.roles

    if (process.env.NODE_ENV === "development") {
      console.log("Token response is obtained", tokenResponse)
    }
  }

  requestNewToken = async () => {
    await this.init()
  }

  logout = async () => {
    this.isLoggedIn = false
    await this.app.logout({
      account: this.app.getAllAccounts()[0],
      postLogoutRedirectUri: "https://www.mandg.co.uk"
    });
  }

}