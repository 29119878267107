import {saveAs} from "file-saver"
import papa from "papaparse"

export default class FileExportService {

  constructor() {}

  async generateCsv(dataType, data, env) {
    let extract;
    let fileName;
    let shortEnv;
    let target;

    if (env.toLowerCase() === "rnd") {
      shortEnv = "rnd"
    } else if (env.toLowerCase() === "nonprd" || env.toLowerCase() === "nonprod") {
      shortEnv = "npd"
    } else if (env.toLowerCase() === "prd" || env.toLowerCase() === "prod") {
      shortEnv = "prd"
    }

    if (dataType === "resourceGroupEntitlements") {
      extract = data.resourceGroupEntitlements
        .map(row => ({
          subscription: row.subscription,
          resourceGroup: row.resourceGroup,
          capabilityTeam: row.capabilityTeam,
        }))

      target = data.principalName

      fileName = `cre_${shortEnv}_${target}.csv`
    } else if (dataType === "subscriptionEntitlements") {
      extract = data.subscriptionEntitlements
        .map(row => ({
          subscription: row.subscription,
          reader: row.access.reader,
          contributor: row.access.contributor,
          owner: row.access.owner
        }))

      target = data.principalName

      fileName = `cse_${shortEnv}_${target}.csv`
    } else {
      extract = data.azureResources
        .map(row => ({
          subscription: row.subscription,
          resourceGroup: row.resourceGroup,
          capabilityTeam: row.capabilityTeam,
        }))

      target = data.resourceName

      fileName = `cae_${shortEnv}_${target}.csv`
    }

    let csvData = papa.unparse(extract)
    let file = new File([csvData], fileName, {type: "text/csv;charset=utf-8"});
    saveAs(file)
  }



}