<template>
  <div id="searchResults">
    <h1 class="is-size-4">Entitlements for <strong class="has-text-gold">{{ apiResponse.principalName }}</strong></h1>

    <template v-if="apiResponse.resourceGroupEntitlements.length > 0">
      <h2 class="pt-3 pb-2 is-size-5">Resource group entitlements</h2>
      <section>
        <b-table :data="this.apiResponse.resourceGroupEntitlements" :paginated="true" :per-page="this.rgTablePageCount"
                 :narrowed="true" :hoverable="true" :debounce-search="250" aria-next-label="Next page"
                 aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
                 @page-change="pageChanged('user-rg-tbl')" detailed id="user-rg-tbl"
                 class="core-table with-details-column" ref="userRgTbl">

          <p class="has-text-right table-pagination-dynamic">
            Showing {{ offsetStartRg }} to {{ offsetEndRg }} of {{ totalRecordsRg }} records
          </p>

          <b-table-column field="subscription" label="Subscription" sortable searchable>
            <template slot="searchable" slot-scope="props">
              <b-input v-model="props.filters[props.column.field]" placeholder="Search..." icon="magnify"
                       size="is-small"/>
            </template>
            <template v-slot="props">
              {{ props.row.subscription }}
            </template>
          </b-table-column>

          <b-table-column field="resourceGroup" label="Resource group" sortable searchable>
            <template slot="searchable" slot-scope="props">
              <b-input v-model="props.filters[props.column.field]" placeholder="Search..." icon="magnify"
                       size="is-small"/>
            </template>
            <template v-slot="props">
              {{ props.row.resourceGroup }}
            </template>
          </b-table-column>

          <b-table-column field="capabilityTeam" label="Capability team" sortable searchable>
            <template slot="searchable" slot-scope="props">
              <b-input v-model="props.filters[props.column.field]" placeholder="Search..." icon="magnify"
                       size="is-small"/>
            </template>
            <template v-slot="props">
              {{ props.row.capabilityTeam }}
            </template>
          </b-table-column>

          <template slot="empty"><p class="has-text-centered">No results found matching criteria</p></template>

          <template slot="detail" slot-scope="props" class="core-table-details">
            <HierarchyTreeForCoreTable :api-response="apiResponse" child-type="user" :row="props.row">
            </HierarchyTreeForCoreTable>
          </template>

          <template slot="bottom-left">
            <b-button @click="exportToCsv('resourceGroupEntitlements')">
              <span class="icon pr-1"><i class="mdi mdi-18px mdi-file-download-outline"></i></span>
              Export to CSV
            </b-button>
          </template>
        </b-table>
      </section>
    </template>
    <template v-else>
      <h2 class="pt-3 pb-2 is-size-5">Resource group entitlements</h2>
      <p>None</p>
    </template>

    <template v-if="apiResponse.subscriptionEntitlements.length > 0">
      <h2 class="pt-5 is-size-5">Subscription entitlements</h2>
      <section>
        <b-table :data="this.apiResponse.subscriptionEntitlements" :paginated="false" :narrowed="true" :hoverable="true"
                 :debounce-search="250" class="core-table" id="user-sub-tbl">

          <div class="level mb-0 pb-0">
            <div class="level-left">
              <p class="has-text-left is-italic">
                <span class="icon"><i class="mdi mdi-18px mdi-information-outline has-text-info"></i></span>
                Click on each icon in the table below to view a detailed access breakdown.
              </p>
            </div>
            <div class="level-right">
              <p class="has-text-right table-pagination-static">
                Showing 1 to {{ apiResponse.subscriptionEntitlements.length }} of
                {{ apiResponse.subscriptionEntitlements.length }} records
              </p>
            </div>
          </div>

          <b-table-column field="subscription" label="Subscription" sortable searchable>
            <template slot="searchable" slot-scope="props">
              <b-input v-model="props.filters[props.column.field]" placeholder="Search..." icon="magnify"
                       size="is-small"/>
            </template>
            <template v-slot="props">
              {{ props.row.subscription }}
            </template>
          </b-table-column>

          <b-table-column field="reader" label="Reader">
            <template v-slot="props">
              <span class="icon" @click="showBreakdownModal(apiResponse.principalName, 'reader', props.row)">
                <i v-if="props.row.access.reader" class="mdi mdi-24px mdi-check-bold green-tick"></i>
                <i v-else class="mdi mdi-24px mdi-close-thick red-cross"></i>
              </span>
            </template>
          </b-table-column>

          <b-table-column field="contributor" label="Contributor">
            <template v-slot="props">
              <span class="icon" @click="showBreakdownModal(apiResponse.principalName, 'contributor', props.row)">
                <i v-if="props.row.access.contributor" class="mdi mdi-24px mdi-check-bold green-tick"></i>
                <i v-else class="mdi mdi-24px mdi-close-thick red-cross"></i>
              </span>
            </template>
          </b-table-column>

          <b-table-column field="owner" label="Owner">
            <template v-slot="props">
              <span class="icon" @click="showBreakdownModal(apiResponse.principalName, 'owner', props.row)">
                <i v-if="props.row.access.owner" class="mdi mdi-24px mdi-check-bold green-tick"></i>
                <i v-else class="mdi mdi-24px mdi-close-thick red-cross"></i>
              </span>
            </template>
          </b-table-column>

          <template slot="empty"><p class="has-text-centered">No results found matching criteria</p></template>
        </b-table>
        <div class="mt-5">
          <b-button @click="exportToCsv('subscriptionEntitlements')">
            <span class="icon pr-1"><i class="mdi mdi-18px mdi-file-download-outline"></i></span>
            Export to CSV
          </b-button>
        </div>
      </section>
    </template>
    <template v-else>
      <h2 class="pt-3 pb-2 is-size-5">Subscription entitlements</h2>
      <p>None</p>
    </template>

  </div>
</template>

<script>
import AccessBreakdownModal from "@/components/Home/AccessBreakdownModal";
import HierarchyTreeForCoreTable from "@/components/Home/HierarchyTrees/HT-CoreTable";
import FileExportService from "@/services/FileExportService";

export default {
  name: 'ResponseTemplate_UserApi',

  data: function () {
    return {
      environment: this.$settings.environment,
      rgTablePageCount: 15,
      offsetStartRg: 1,
      offsetEndRg:  Math.min(this.$props.apiResponse.resourceGroupEntitlements.length, 15),
      totalRecordsRg: this.$props.apiResponse.resourceGroupEntitlements.length
    }
  },

  components: {
    AccessBreakdownModal: AccessBreakdownModal,
    HierarchyTreeForCoreTable: HierarchyTreeForCoreTable
  },

  props: {
    apiResponse: Object
  },

  async mounted() {
    // Cache the inheritance data from the first page of the RG entitlements table
    let initialRgTableRows = this.$props.apiResponse.resourceGroupEntitlements.slice(0, this.rgTablePageCount)

    // noinspection ES6MissingAwait
    this.preEmptiveInheritanceCacheForRgRows(initialRgTableRows)

    // Watchers to monitor table changes for dynamic pagination
    this.$watch("$refs.userRgTbl.newDataTotal", (new_value, old_value) => {
      if (new_value === 0) {
        this.offsetStartRg = 0
        this.offsetEndRg = 0
      } else {
        this.offsetStartRg = (this.$refs.userRgTbl.newCurrentPage - 1) * this.rgTablePageCount + 1
        this.offsetEndRg = Math.min(this.rgTablePageCount, new_value)
      }
      this.totalRecordsRg = new_value
    });

    this.$watch("$refs.userRgTbl.newCurrentPage", (new_value, old_value) => {
      this.offsetStartRg = (new_value - 1) * this.rgTablePageCount + 1
    });

    this.$watch("$data.offsetStartRg", (new_value, old_value) => {
      let offsetEnd = new_value + (this.rgTablePageCount - 1)
      this.offsetEndRg = Math.min(offsetEnd, this.$refs.userRgTbl.newDataTotal)
    });
  },

  methods: {
    showBreakdownModal: function (principalName, roleName, subscriptionEntitlements) {
      this.$buefy.modal.open({
        parent: this,
        component: AccessBreakdownModal,
        props: {
          principalName: principalName,
          roleName: roleName,
          subscriptionEntitlements: subscriptionEntitlements,
          apiResponse: this.$props.apiResponse
        },
        hasModalCard: true,
        trapFocus: true
      })
    },

    preEmptiveInheritanceCacheForRgRows: async function (rowsOnPage) {
      // Get the distinct capabilities
      let uniqueCapabilities = rowsOnPage.filter((row, index, self) =>
          index === self.findIndex((t) => (
              t.capabilityTeam === row.capabilityTeam && t.capabilityTeamObjectId === row.capabilityTeamObjectId
          ))
      )

      // Set the isCached property to false by default
      uniqueCapabilities.forEach((row, index, array) => {
        row["isCached"] = false
      })

      // Mark the rows which don't need to be cached
      let childUpn = this.$props.apiResponse.principalName.toString().toLowerCase();
      uniqueCapabilities.forEach((row, index, array) => {
        let isCached = this.$cacheService.getItemFromInheritanceCache(
            row.capabilityTeam,
            row.capabilityTeamObjectId,
            childUpn
        )
        if (isCached !== false) {
          row["isCached"] = true
        }
      })

      let toBeCached = uniqueCapabilities.filter(row => row.isCached === false)
      let tobeCachedUrls = []
      let cachedResults = []

      // Populate an array of URLs that we will later need to call to populate the cache
      toBeCached.forEach((row, index, array) => {
        let url = this.$settings.apiBaseUri
        url += `EnumerateGroupInheritance?childType=user`
        url += `&parentGroupName=${row.capabilityTeam}`
        url += `&parentGroupId=${row.capabilityTeamObjectId}`
        url += `&upn=${childUpn}`

        tobeCachedUrls.push(url)
      })

      // Call each of the URLs in parallel
      await Promise.all(tobeCachedUrls.map(url => this.$http.get(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${(this.$authService.rbacAccessToken)}`
        }
      }))).then((function (result) {
        cachedResults.push([result])
      }))

      // Append hierarchies to caching service
      cachedResults[0][0].forEach((result, index, array) => {
        let queryStrings = new URLSearchParams(result.config.url)
        this.$cacheService.addItemToInheritanceCache(
            queryStrings.get("parentGroupName"),
            queryStrings.get("parentGroupId"),
            childUpn,
            result.data
        )
      })
    },

    pageChanged: async function (tableName) {
      // When the page is changed, reset any expanded/opened detail rows
      let tableIndex = this.$children.findIndex((child) => child.$vnode.data.attrs.id === tableName)
      this.$children[tableIndex].openedDetailed = []

      if (tableName === 'user-rg-tbl') {
        // Get the visible table rows from the current table page
        let rowsOnPage = this.$children[
            this.$children.findIndex((child) => child.$vnode.data.attrs.id === tableName)
            ].visibleData

        // noinspection ES6MissingAwait
        this.preEmptiveInheritanceCacheForRgRows(rowsOnPage)
      }
    },

    exportToCsv: async function (entitlementType) {
      let fileExportService = new FileExportService()
      let data = this.$props.apiResponse;
      let env = this.$settings.environment
      await fileExportService.generateCsv(entitlementType, data, env)
    },
  },

  filters: {}
}
</script>