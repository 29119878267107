export default class CacheService {

  inheritances = new Map()

  constructor() {}

  addItemToInheritanceCache(parentGroupName, parentGroupId, upnOrChildGroupId, hierarchy) {
    let key = JSON.stringify([parentGroupName, parentGroupId, upnOrChildGroupId]) // stringify is a bit dirty but it'll do the job?
    let value = hierarchy

    this.inheritances.set(key, value)
  }

  getItemFromInheritanceCache(parentGroupName, parentGroupId, upnOrChildGroupId) {
    let key = JSON.stringify([parentGroupName, parentGroupId, upnOrChildGroupId]) // stringify is a bit dirty but it'll do the job?

    let result = this.inheritances.get(key);
    if (result === undefined) {
      return false
    } else {
      return result
    }
  }

  getAllItemsFromInheritanceCache() {
    return this.inheritances
  }

}