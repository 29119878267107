<template>
  <div id="app">

    <div id="app-logged-in" v-if="this.$authService.isLoggedIn === true">
      <header>
        <div class="container">
          <b-navbar>
            <template slot="brand">
              <b-navbar-item v-on:click="renderHome()"><abbr
                  title="Cloud Access Review Management Application" class="is-size-4">CARMA</abbr>
                <small class="pl-2"><em>{{ this.$settings.environment | prettifiedEnvironmentName }}</em></small>
              </b-navbar-item>
            </template>
            <template slot="start">
              <b-navbar-dropdown collapsible>
                <template slot="label">Switch environment</template>
                <b-navbar-item href="https://carma-rnd.mandg.co.uk">R&D</b-navbar-item>
                <b-navbar-item href="https://carma-nonprod.mandg.co.uk">Nonprod</b-navbar-item>
                <b-navbar-item href="https://carma.mandg.co.uk">Prod</b-navbar-item>
              </b-navbar-dropdown>
              <b-navbar-item tag="router-link" :to="{ path: 'help' }">Help</b-navbar-item>
            </template>

            <template slot="end">
              <b-navbar-item v-on:click="initiateLogout()">
                <span class="icon" style="padding-right: 5px"><i class="mdi mdi-18px mdi-logout"></i></span>
                Sign out
              </b-navbar-item>
            </template>
          </b-navbar>
        </div>
      </header>

      <router-view/>

      <app-footer></app-footer>
    </div>

    <div id="app-logged-out-spinner" v-else>
      <div class="container">
        <div class="content">
          <b-notification :closable="false">
            <b-loading :is-full-page="true" :can-cancel="false" :active="true"></b-loading>
          </b-notification>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import Footer from '@/components/Generic/Footer'

export default {
  components: {
    'app-footer': Footer
  },

  mounted: function () {
  },

  methods: {
    initiateLogout: function () {
      this.$authService.logout();
    },

    renderHome: function () {
      if (this.$route.name === "Home") {
        let homeIndex = this.$children.findIndex((child) => child.$options.name === "Home")
        let searchFormIndex = this.$children[homeIndex].$children.findIndex((child) => child.$options.name === "SearchForm")
        this.$children[homeIndex].$children[searchFormIndex].switchToSearchPage("user")
      } else {
        this.$router.push('/')
      }
    }
  },

  filters: {
    prettifiedEnvironmentName: function (value) {
      if (value.toLowerCase() === "rnd") {
        return 'R&D'
      } else if (value.toLowerCase() === "nonprd" || value.toLowerCase() === "nonprod") {
        return "NonProd"
      } else if (value.toLowerCase() === "prd" || value.toLowerCase() === "prod") {
        return "Prod"
      }
    }
  }
}
</script>