import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/', name: 'Home', component: Home, meta: { title: 'CARMA' }
  },
  {
    path: '/home', redirect: '/'
  },
  {
    // route level code-splitting
    // this generates a separate chunk (help.[hash].js) for this route which is lazy-loaded when the route is visited.
    path: '/help', name: 'Help', meta: { title: 'Help - CARMA' }, component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue'),
  },
  { 
    path: '/404', component: () => import('../views/404.vue'), meta: { title: 'Page not found - CARMA' }
  },
  { 
    path: '*', redirect: '/404' 
  }
]

const router = new VueRouter({
  routes
})

router.afterEach((to, from) => {
  Vue.nextTick(function () {
    document.title = to.meta.title
  })

})

export default router
