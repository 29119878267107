<template>
  <div>
    <p id="searchInstructions" class="has-text-centered is-size-5 pb-6">To get started, choose an option below</p>

    <nav class="tabs is-centered is-fullwidth is-boxed is-large">
      <ul>
        <li v-bind:class="{ 'is-active': this.currentSearchPage === 'user' }" v-on:click="switchToSearchPage('user')">
          <a><span>Search by UPN</span></a></li>
        <li v-bind:class="{ 'is-active': this.currentSearchPage === 'group' }" v-on:click="switchToSearchPage('group')">
          <a><span>Search by AD group</span></a></li>
        <li v-bind:class="{ 'is-active': this.currentSearchPage === 'resource' }" v-on:click="switchToSearchPage('resource')">
          <a><span>Search by subscription</span></a></li>
      </ul>
    </nav>

    <div class="field">
      <template>
        <section>
          <b-field>
            <b-autocomplete size="is-large" v-bind:placeholder="this.placeholderTexts[this.currentSearchPage]"
                            icon="magnify"
                            id="searchQuery" ref="searchQuery" clearable :loading="isFetchingAutocompleteData"
                            v-model.lazy.trim="searchQuery"
                            :data="autocompleteData" @typing="getAutocompleteDataAsync"
                            @select="option => submitSearch(option)">
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content"><span>{{ props.option }}</span></div>
                </div>
              </template>
            </b-autocomplete>
          </b-field>
        </section>
      </template>
    </div>

  </div>
</template>

<script>
import {debounce, throttle} from "throttle-debounce";

export default {
  name: "SearchForm",

  data: function () {
    return {
      currentSearchPage: null,
      searchQuery: "",
      latestSearchQuery: "", // use this variable to avoid wrongly ordered async responses,
      placeholderTexts: {
        'user': 'e.g. john.smith@mandg.co.uk',
        'group': 'e.g. CD_MG_Role_RiskAndPerformance_Engineers',
        'resource': this.contextfulPlaceholderText('resource')
      },
      autocompleteData: [],
      isFetchingAutocompleteData: false,
      isLoading: null,
      ignoreLateArrivingEntitlementsApiResponse: false
    }
  },

  watch: {
    '$route.query'() {
      this.detectChangesToQueryStrings()
    }
  },

  mounted() {
    this.detectChangesToQueryStrings()
  },

  props: {
    apiResponse: Object
  },

  methods: {
    switchToSearchPage: function (searchPage, updateQueryStrings = true) {
      this.currentSearchPage = searchPage
      this.resetSearchBar()
      this.$refs.searchQuery.focus() // change user focus to input element
      this.setIsServerError(false)
      this.setIsLoading(false)
      this.setApiResponse(null)
      this.latestSearchQuery = ""
      this.$appInsights.trackPageView({name: "Home" + " (SearchForm) " + searchPage})

      if (updateQueryStrings) this.updateUrlQueryStrings(searchPage)
    },

    resetSearchBar: function () {
      this.autocompleteData = []
      this.isFetchingAutocompleteData = false
      this.searchQuery = ""
    },

    getAutocompleteDataAsync: async function (searchQuery) {
      if (searchQuery.length && searchQuery.length < 5) {
        throttle(500, await this.getDataFromAutoCompleteApi(searchQuery))
      } else {
        debounce(500, await this.getDataFromAutoCompleteApi(searchQuery))
      }
    },

    getDataFromAutoCompleteApi: async function (searchQuery) {
      if (!searchQuery.length) {
        this.autocompleteData = []
        return
      }

      this.isFetchingAutocompleteData = true

      this.latestSearchQuery = searchQuery

      if (this.currentSearchPage === "user") {
        let url = `https://graph.microsoft.com/beta/users?$filter=startswith(userPrincipalName,'${searchQuery}') and (accountEnabled eq true)&$select=userPrincipalName&$top=10`

        this.$http.get(url, {
          method: "GET",
          headers: {
            'Authorization': `Bearer ${(this.$authService.graphAccessToken)}`
          }
        }).then(response => {
          if (response.status !== 200) {
            this.autocompleteData = []
            console.error(`Failed to get autocomplete data, autocomplete API returned HTTP ${httpResponseCode}`)
            return
          }

          if (this.latestSearchQuery === searchQuery) {
            this.autocompleteData = []
            response.data.value.forEach((item) => this.autocompleteData.push(item.userPrincipalName.toLowerCase()))
            this.isFetchingAutocompleteData = false
          }
        })
      }

      if (this.currentSearchPage === "group") {
        let url = `https://graph.microsoft.com/beta/groups?$select=displayName&$filter=startswith(displayName,'${searchQuery}') and (securityEnabled eq true)&$top=10`

        this.$http.get(url, {
          method: "GET",
          headers: {
            'Authorization': `Bearer ${(this.$authService.graphAccessToken)}`
          }
        }).then(response => {
          if (response.status !== 200) {
            this.autocompleteData = []
            console.error(`Failed to get autocomplete data, autocomplete API returned HTTP ${httpResponseCode}`)
            return
          }

          if (this.latestSearchQuery === searchQuery) {
            this.autocompleteData = []
            response.data.value.forEach((item) => this.autocompleteData.push(item.displayName))
            this.isFetchingAutocompleteData = false
          }
        })
      }

      if (this.currentSearchPage === "resource") {
        let subscriptionsList = this.$allSubscriptionsInManagementGroup

        let filteredSubscriptions = subscriptionsList?.filter((subscription) =>
            subscription.startsWith(searchQuery.toString().toLowerCase()))

        this.autocompleteData = []
        filteredSubscriptions.forEach((item) => this.autocompleteData.push(item))
        this.isFetchingAutocompleteData = false
      }

    },

    submitSearch: function (searchQuery) {
      if (searchQuery === null || searchQuery.trim() === "") {
        return
      }
      this.setIsServerError(false)
      this.setIsLoading(true)
      this.setApiResponse()
      this.fetchSearchResults(searchQuery)

      this.updateUrlQueryStrings(this.currentSearchPage, searchQuery)
    },

    fetchSearchResults: async function (searchQuery) {
      if (searchQuery === null || searchQuery.trim() === "") {
        return false
      }

      let url = this.$settings.apiBaseUri

      switch (this.currentSearchPage) {
        case 'user':
          url += `EnumerateAccessByUser?upn=${searchQuery}`
          break
        case 'group':
          url += `EnumerateAccessByGroup?group=${searchQuery}`
          break
        case 'resource':
          url += `EnumerateAccessByAzureResource?resource=${searchQuery}`
          break
        default:
          console.error("Search results could not be fetched")
      }

      let response = await this.$http.get(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${(this.$authService.rbacAccessToken)}`
        },
      })

      if (this.ignoreLateArrivingEntitlementsApiResponse) {
        console.log("Rejecting late arriving response because the user has changed search tab")
        this.ignoreLateArrivingEntitlementsApiResponse = false
      } else if (response.status === 200) {
        this.apiResponse.endpointType = this.currentSearchPage
        this.apiResponse.apiResponse = response.data
      } else if (response.status >= 500 && response.status < 600) {
        this.setIsServerError(true)
      }

      this.setIsLoading(false) // disable the page loading spinner
    },

    contextfulPlaceholderText: function (pageType) {
      if (pageType === 'resource' && this.$settings.environment === 'rnd') {
        return 'e.g. sub-mg-rnd-01'
      }

      if (pageType === 'resource' && this.$settings.environment === 'nonprd') {
        return 'e.g. sub-mg-nonprd-t1-01'
      }

      if (pageType === 'resource' && this.$settings.environment === 'prd') {
        return 'e.g. sub-lf-prd-t1-01'
      }
    },

    /* Parent-child two-way data relay methods */
    setIsLoading: function (newState) {
      // If the loading spinner is currently enabled (i.e. mid-way through a long-running API call) when the user tries
      // to suddenly change search tab, we need to keep track of this by using the ignore late arrivals flag.
      //
      // We do this to prevent (for example) user entitlement data showing AFTER the user switches to the
      // group entitlement tab
      this.ignoreLateArrivingEntitlementsApiResponse = (this.isLoading === true && newState === false) ? true : false

      this.isLoading = newState
      this.$emit("childChangesLoadingState", newState)
    },

    setIsServerError: function (newState) {
      this.isServerError = newState
      this.$emit("childChangesServerErrorState", newState)
    },

    setApiResponse: function () {
      this.apiResponse.apiResponse = null
      this.apiResponse.endpointType = null
      this.$emit("childResetsApiResponse", null)
    },

    updateUrlQueryStrings: function (searchBy, query = null) {
      if (!['user','group','resource'].includes(this.$route.query.searchBy)) {
        searchBy = 'user'
      }

      if (query === null || query === undefined) {
        return this.$router.push({ query: { 'searchBy': searchBy }}).catch(()=>{})
      } else {
        return this.$router.push({ query: { 'searchBy': searchBy, 'query': query }}).catch(()=>{})
      }
    },

    detectChangesToQueryStrings: function() {
      let searchBy = !['user', 'group', 'resource'].includes(this.$route.query.searchBy) ? 'user' : this.$route.query.searchBy;
      if (!this.$route.query.query) {
        return this.switchToSearchPage(searchBy)
      }

      if (this.$route.query.query) {
        this.switchToSearchPage(searchBy, false)
        this.searchQuery = this.$route.query.query
        this.submitSearch(this.$route.query.query)
      }
    }
  }
}
</script>

<style scoped>

</style>