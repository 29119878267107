<template>
  <div class="generic-page-container container search">

    <b-notification type="is-warning is-light" has-icon aria-close-label="Close notification" role="alert">
      <p class="has-text-weight-semibold">Proceed with caution</p>
      <p v-if="isRnd">You're using the R&D instance of CARMA which is still in beta. This instance only
        provides a limited amount of data at the moment.</p>
      <p v-else>CARMA is still in beta. If you encounter any bugs, please report them.</p>
    </b-notification>

    <SearchForm :api-response="apiResponse" @childChangesLoadingState="setIsLoading"
                @childChangesServerErrorState="setIsServerError" @childResetsApiResponse="resetApiResponse" />

    <div id="server-error-500" v-if="this.isServerError" class="pt-6">
      <article class="message is-danger">
        <div class="message-header">
          <p>Could not retrieve search results</p>
          <button class="delete" aria-label="delete" v-on:click="isServerError = !isServerError"></button>
        </div>
        <div class="message-body">
          <p>An error occurred that prevented CARMA from retrieving search results. Please try again later.</p>
          <p>For support, please contact the <a href="mailto:MandG.IT.Ops.Cloud.Engineers@MandG.co.uk?Subject=CARMA error">Enterprise Cloud Engineering</a> team.</p>
        </div>
      </article>
    </div>

    <div v-show="isLoading" class="loadingSpinner columns is-centered">
      <b-notification :closable="false" class="column is-1 mt-6" style="height: 110px;">
        <b-loading style="border-radius: 10px; " v-model="isLoading" :is-full-page="false" :closable="false"></b-loading>
      </b-notification>
    </div>

    <ResponseTemplate_UserApi     class="mt-5" v-bind:apiResponse="this.apiResponse.apiResponse" v-if="(this.apiResponse.apiResponse) && (this.apiResponse.endpointType === 'user')" />
    <ResponseTemplate_GroupApi    class="mt-5" v-bind:apiResponse="this.apiResponse.apiResponse" v-if="(this.apiResponse.apiResponse) && (this.apiResponse.endpointType === 'group')" />
    <ResponseTemplate_ResourceApi class="mt-5" v-bind:apiResponse="this.apiResponse.apiResponse" v-if="(this.apiResponse.apiResponse) && (this.apiResponse.endpointType === 'resource')" />

  </div>
</template>

<script>
import SearchForm from "@/components/Home/SearchForm";
import ResponseTemplate_UserApi from '@/components/Home/ResponseTemplate_UserApi.vue'
import ResponseTemplate_GroupApi from '@/components/Home/ResponseTemplate_GroupApi.vue'
import ResponseTemplate_ResourceApi from '@/components/Home/ResponseTemplate_ResourceApi.vue'

export default {
  name: 'Home',
  components: {
    SearchForm,
    ResponseTemplate_UserApi,
    ResponseTemplate_GroupApi,
    ResponseTemplate_ResourceApi,
  },

  data: function () {
    return {
      isServerError: false,
      apiResponse: {
        'endpointType': null,
        'apiResponse': null
      },
      isLoading: false,
      isRnd: (this.$settings.environment === 'rnd')
    }
  },

  mounted() {},

  methods: {
    setIsLoading: function (booleanState) {
      this.isLoading = booleanState
    },

    setIsServerError: function (booleanState) {
      this.isServerError = booleanState
    },

    resetApiResponse: function () {
      this.apiResponse.apiResponse = null
      this.apiResponse.endpointType = null
    }
  },

  filters: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>